export const adminsUrl = '/api/app-admins';
export const loginUrl = '/api/users/login';
export const dpoProviderUrl = '/api/dpo-providers';
export const siretCheckUrl = '/extApi/entreprises/sirene/v3/siret';
export const organizationGroupsUrl = '/api/organisation-groups';
export const organizationsUrl = '/api/organisations';
export const usersUrl = '/api/users';
export const consultantsUrl = '/api/consultants';
export const packagesUrl = '/api/packages';
export const legalBasisUrl = '/api/references/legal-base';
export const personsRangeUrl = '/api/references/person-range';
export const personsCategoriesUrl = '/api/references/person-category';
export const treatmentsOperationsUrl = '/api/references/treatment-operation';
export const personContactMethodUrl = '/api/references/person-contact-method';
export const NAFsUrl = '/api/references/nafs';
export const personInformMethodUrl =
  '/api/references/person-information-method';
export const techAndOrgUrl = '/api/references/tech-org-measure';
export const dataThreatUrl = '/api/references/data-threat';
export const dataThreatsByCategoriesUrl =
  '/api/data-threat-categories/data-threats';
export const dataThreatCategoriesUrl = '/api/data-threat-categories';
export const dataRangeUrl = '/api/references/data-count-range';
export const retentionRangeUrl = '/api/references/retention-period';
export const activitySectorsUrl = '/api/references/activity-sectors';
export const treatmentOpsNumberUrl =
  '/api/references/treatment-operation-number-range';
export const transferLegalBaseRiskGradeUrl =
  '/api/references/transfer-legal-base-risk-grade';
export const transferCountriesUrl =
  '/api/references/transfer-country-risk-grade';
export const resetPasswordUrl = '/api/users/reset-password';
export const recoverPasswordUrl = '/api/users/request-password-reset';
export const genericRegistryCodesUrl = '/api/generic-registry-codes';
export const treatmentTemplatesUrl = '/api//treatment-templates';
export const registryTemplatesUrl = '/api/registry-templates';
export const treatmentTemplateAffiliationTemplatesUrl =
  '/api/treatment-template-affiliation-templates';
export const uploadFilesUrl = '/uploadApi/files';
export const newsUrl = '/api/rssnews';
export const announcementUrl = '/api/references/announcement';
export const moduleAnnouncementUrl = '/api/references/modules-announcement';
export const functionalityUrl = '/api/references/functionality';
export const chatIAUrl = '/api/chat-ia';
export const conformityTemplatesUrl = '/api/conformity-templates';
export const chatIABlackListUrl = '/api/chat-ia-black-list';
